import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import './Performance.css'; // Optional for custom styling

export default function Performance() {
  const percentage = 75; // This can be dynamic or passed as a prop

  return (
    <div className="performance-container">
      <div className="progress-circle">
        <CircularProgressbar
          value={percentage}
          text={`Performance`}
          styles={buildStyles({
            pathColor: "#008772", // Green color for the progress
            textColor: "#008772", // Green color for the text
            trailColor: "#d6d6d6", // Gray color for the remaining path
            textSize: "11px", // Adjust text size
          })}
        />
      </div>
      <div className="performance-info">
        <p>Rate of Previous Assignments</p>
        <span className="percentage-box">{percentage} %</span>
      </div>
    </div>
  );
}
