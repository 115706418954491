import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { FaArrowUp } from "react-icons/fa";

const revenueData = [
  { day: "S", revenue: 20000 },
  { day: "M", revenue: 30000 },
  { day: "T", revenue: 41000 },
  { day: "W", revenue: 25000 },
  { day: "T", revenue: 36000 },
  { day: "F", revenue: 50000 },
  { day: "S", revenue: 46000 },
];

const RevenueCard = () => (
  <div
    style={{
      border: "1px solid #eee",
      backgroundColor: "#f8f9fa",
      borderRadius: "10px",
      padding: "20px",
      margin: "10px",
    }}
  >
    <h2>
      <FaArrowUp style={{ color: "green", marginRight: "5px" }} />
      $563,982.74
    </h2>
    <p>Total Revenue</p>
    <ResponsiveContainer width="100%" height={150}>
      <LineChart data={revenueData}>
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="revenue"
          stroke="green"
          strokeWidth={3}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default RevenueCard;
