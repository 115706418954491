import React, { useState } from "react";
import Style from "./Login.module.css";
import loginimg from "../../Assets/img8.jpg";
import ggl from "../../Assets/gglicn.png";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useLoginMutation } from "../../api/apiSlice";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useDarkMode } from "../Contexts/DarkModeContext";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { darkMode } = useDarkMode();
  const isScreenSmall = useMediaQuery({ maxWidth: 576 });
  let navigate = useNavigate();
  const [error, setError] = useState("");

  const [login] = useLoginMutation();

  const { t } = useTranslation();

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const credentials = { identifier: data.email, password: data.password };

    try {
      const userData = await login(credentials).unwrap();
      console.log("userData", userData);
      message.success(t("login.loginSuccess"));
      const userRole = userData?.user?.role;

      switch (userRole) {
        case "admin":
          navigate("/admin-dashboard");
          break;
        case "student":
          navigate("/library");
          break;
        default:
          navigate("/");
          break;
      }
    } catch (error) {
      setError(t("login.networkError"));
      console.error("Error logging in:", error);
      message.error(t("login.loginFailed"));
      setTimeout(() => setError(""), 3000);
    }
  };

  return (
    <>
      <div className={`${darkMode ? 'bg-dark' : 'bg-white'}`}>
        <div className="container pt-5">
          <div className="row py-5">
            <div className="col-md-6">
              <img src={loginimg} className="w-100 h-100 rounded-2" alt="" />
            </div>
            <div className="col-md-6">
              <div>
                <h5 className={`fw-bold text-center font-md ${darkMode ? 'text-white' : 'text-black'}`}>
                  {t("login.welcomeMessage")}
                </h5>
                <div className="d-flex justify-content-center align-items-center">
                  <h6 className="text-color m-0">{t("login.orConnectWith")}</h6>
                  <div className="d-flex align-items-top">
                    <div>
                      <i className={`${Style.blue} fa-brands fa-facebook mx-3`}></i>
                    </div>

                    <div style={{ width: "15px", height: "15px" }} className="">
                      <img src={ggl} className="w-100 mb-1 " alt="google logo" />
                    </div>
                  </div>
                </div>

                {/* Form using React Hook Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={`${isScreenSmall ? "px-2" : "mt-5 px-5"}`}>
                    <label htmlFor="email" className={`form-label ${darkMode ? 'text-white' : 'text-black'} fs-5 fw-bold`}>
                      {t("login.username")}
                    </label>
                    <input
                      type="text"
                      className="w-100 py-2 gray-border rounded-5 px-3"
                      id="email"
                      placeholder={t("login.emailPlaceholder")}
                      {...register("email", {
                        required: t("login.emailRequired"),
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: t("login.emailInvalid"),
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="text-danger mt-1">
                        {errors.email.message}
                      </div>
                    )}
                  </div>

                  <div className={`${isScreenSmall ? "px-2 mt-3" : "mt-5 px-5"}`}>
                    <label htmlFor="password" className={`form-label ${darkMode ? 'text-white' : 'text-black'} fs-5 fw-bold`}>
                      {t("login.password")}
                    </label>
                    <input
                      type="password"
                      className="w-100 py-2 gray-border rounded-5 px-3"
                      id="password"
                      placeholder={t("login.passwordPlaceholder")}
                      {...register("password", {
                        required: t("login.passwordRequired"),
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                          message: t("login.passwordInvalid"),
                        },
                      })}
                    />
                    {errors.password && (
                      <div className="text-danger mt-1">
                        {errors.password.message}
                      </div>
                    )}
                    <div className="d-flex justify-content-between">
                      <div className="d-flex px-3 py-2 fw-bold">
                        <input type="checkbox" />
                        <p className={`m-0 small-font px-1 ${darkMode ? 'text-white' : 'text-black'}`}>{t("login.rememberMe")}</p>
                      </div>
                      <Link className="small-font py-2 m-0 fw-bold">
                        <a href="#">{t("login.forgetPassword")}</a>
                      </Link>
                    </div>
                  </div>

                  {error && (
                    <div className="alert alert-danger mt-3">{error}</div>
                  )}

                  <div
                    className={`d-flex justify-content-end ${
                      isScreenSmall ? "pe-3" : " pe-5"
                    }`}
                  >
                    <button
                      type="submit"
                      className="btn bg-color rounded-5 text-white px-4 py-1"
                    >
                      {t("login.loginButton")}
                    </button>
                  </div>
                </form>

                <div className="d-flex justify-content-center align-items-center mt-4 ">
                  <h6 className={`m-0 me-1 small-font fw-bold ${darkMode ? 'text-white' : 'text-black'}`}>
                    {t("login.dontHaveAccount")}{" "}
                  </h6>
                  <Link to={"/signup"} className="text-decoration-none">
                    <h6 className="text-color m-0 small-font fw-bold">
                      {t("login.registerNow")}
                    </h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
