import React from "react";
import { FaCheckCircle, FaTimesCircle, FaClock } from "react-icons/fa";

const transactions = [
  {
    name: "XYZ Store ID",
    date: "June 4, 2020",
    amount: "+$5,553",
    status: "Completed",
  },
  {
    name: "Chef Renata",
    date: "June 5, 2020",
    amount: "-$167",
    status: "Pending",
  },
  {
    name: "Cindy Alexandro",
    date: "June 5, 2020",
    amount: "+$5,553",
    status: "Canceled",
  },
  {
    name: "Paypal",
    date: "June 5, 2020",
    amount: "+$5,553",
    status: "Completed",
  },
  {
    name: "Hawkins Jr.",
    date: "June 5, 2020",
    amount: "-$167",
    status: "Canceled",
  },
];

const Transactions = () => {
  const containerStyle = {
    backgroundColor: "#f8f9fa",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const transactionStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Completed":
        return <FaCheckCircle color="green" />;
      case "Pending":
        return <FaClock color="orange" />;
      case "Canceled":
        return <FaTimesCircle color="red" />;
      default:
        return null;
    }
  };

  return (
    <div style={containerStyle}>
      <h3>Previous Transactions</h3>
      {transactions.map((transaction, index) => (
        <div key={index} style={transactionStyle}>
          <div>{getStatusIcon(transaction.status)}</div>
          <div>
            <h4>{transaction.name}</h4>
            <p>{transaction.date}</p>
          </div>
          <div>{transaction.amount}</div>
          <div>{transaction.status}</div>
        </div>
      ))}
    </div>
  );
};

export default Transactions;
