import React from "react";
import Style from "./Footer.module.css";
import logo from "../../../Assets/footerlogo.png";
import facebook from "../../../Assets/Facebook.png";
import IG from "../../../Assets/IG.png";
import iconLanguage from "../../../Assets/🦆 icon _language_.png";
import linkedIn from "../../../Assets/LinkedIn.png";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

export default function Footer() {
  const isScreenSmall = useMediaQuery({ maxWidth: 768 });
  const icon_size = { width: "2.6rem", height: "2.6rem" };
  const small_text = { color: "#F2F2F2", fontSize: 12, fontWeight: 400 };

  const { t, i18n } = useTranslation(); // Use t for translations

  return (
    <footer className={`bg-black  ${Style.footer_container}`}>
      <div className="container  ">
        {/* Logo and products */}
        <div
          className={`logo-products  d-flex flex-row justify-content-between ${
            isScreenSmall ? " d-flex flex-column" : ""
          }`}
        >
          <div className="d-flex align-items-center justify-content-center ">
            <a href="/">
              <img
                src={logo}
                className={`${Style.customSize} rounded-pill`}
                alt="footerLogo"
              />
            </a>
            <div className="border-start ps-3 py-2 text-white mx-2 border-opacity-75 border-white">
              <h6
                className="font-sm fw-bold"
                style={{ wordWrap: "break-word", overflowWrap: "break-word" }}
              >
                {t("footer.bestPlatform")}
              </h6>
            </div>
          </div>
          <div className={`${(Style.a, Style.ul)}`}>
            <h5 className="text-white">{t("footer.educationalMaterials")}</h5>
            <ul className={`${Style.li}`}>
              <li>
                <a href="#">{t("footer.ebooks")}</a>
              </li>
              <li>
                <a href="#">{t("footer.guides")}</a>
              </li>
              <li>
                <a href="#">{t("footer.articles")}</a>
              </li>
              <li>
                <a href="#">{t("footer.blogs")}</a>
              </li>
              <li>
                <a href="#">{t("footer.liveEvents")}</a>
              </li>
            </ul>
          </div>
          <div>
            <h5 className="text-white">{t("footer.courses")}</h5>
            <ul>
              <li>
                <a href="#">{t("footer.design")}</a>
              </li>
              <li>
                <a href="#">{t("footer.marketing")}</a>
              </li>
              <li>
                <a href="#">{t("footer.cybersecurity")}</a>
              </li>
              <li>
                <a href="#">{t("footer.programming")}</a>
              </li>
              <li>
                <a href="#">{t("footer.business")}</a>
              </li>
            </ul>
          </div>
          {/* the newsletter part */}
          <div className={`${Style.news_letter_container} `}>
            <h5 className="text-white">{t("footer.newsletter")}</h5>
            <p style={{ color: "#7D7D7D" }}>
              {t("footer.newsletterDesc")}
            </p>

            <div className={`${Style.textbox_with_button}  text-sm rounded-4`}>
              <input
                className={Style.input}
                type="text"
                placeholder={t("footer.emailPlaceHolder")}
              />

              <button
                className={`${Style.news_btn} ${
                  isScreenSmall ? "" : "px-4"
                } btn bg-color  hover:bg-green-700 text-white  small-font `}
                style={{ height: "46px" }}
              >
                {t("footer.subscribe")}
              </button>
              <div className="flex items-center">
                <input type="checkbox" className="mx-2" />
                <span className="text-sm" style={small_text}>
                  {t("footer.agreeTerms")}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* the footer second section */}
        <div className="py-3 d-flex flex-column flex-md-row justify-content-between">
          <div className={`${Style.language_part}`}>
            <img
              className={Style.iconLanguage}
              src={iconLanguage}
              alt="language icon"
            />
            <p style={{ color: "#FFFFFF", fontSize: 17, fontWeight: "bold" }}>
              {t("footer.language")}
            </p>
          </div>
          <div className={`${Style.conditions_part}`}>
            <ul className={`${Style.conditions_container}`}>
              <li>
                <a className={Style.link} href="#">
                  {t("footer.careers")}
                </a>
              </li>
              <li>
                <a className={Style.link} href="#">
                  {t("footer.privacyPolicy")}
                </a>
              </li>
              <li>
                <a className={Style.link} href="#">
                  {t("footer.termsConditions")}
                </a>
              </li>
            </ul>
          </div>
          <div
            className={`d-flex flex-row justify-content-between ${Style.icons_part}`}
            style={{ flexWrap: "wrap" }}
          >
            <img style={icon_size} src={facebook} alt="facebook" />
            <img style={icon_size} src={IG} alt="IG" />
            <img style={icon_size} src={linkedIn} alt="linkedIn" />
          </div>
        </div>
      </div>
    </footer>
  );
}
