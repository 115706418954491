import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Typography,
  Col,
  InputNumber,
  Input,
  Select,
  Row,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { Controller, useForm } from "react-hook-form";
import { useAddCourseMutation } from "../../api/apiSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";
import { useSelector } from "react-redux";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const AddCourse = () => {
  const { user } = useSelector((state) => state.auth);
  const [addCourse] = useAddCourseMutation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    category: "",
    instructor: user.id,
    level: "",
    sections: "",
    duration: "",
    image: "",
    price: "",
  });
  // const {
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  // } = useForm();

  function getData(e) {
    let data = { ...formData };
    if([e.target.name]=='image'){
      data[e.target.name] = e.target.files[0];
    }else{
      data[e.target.name] = e.target.value;
    }
    setFormData(data);
    console.log(data);
  }

  function handleSubmit(e){
    e.preventDefault();
    const token = localStorage.getItem("token_Value")
    axios.post(BaseURL + `courses/`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }).then((response)=>{
      console.log('data sent',response)
      alert('Couses was added successfully')
    }).catch((err)=>{
      console.log(err)
    })
  }

  //   const [fileList, setFileList] = useState([]);

  //   const handleFileChange = (info) => {
  //     let newFileList = [...info.fileList];
  //     // Limit the number of files
  //     newFileList = newFileList.slice(-1); // Keep only the latest file

  //     setFileList(newFileList);
  //     // Set the value in react-hook-form
  //     setValue("image", newFileList[0] ? newFileList[0].originFileObj : null);
  //   };

  // const onSubmit = async (data) => {

    // const token = localStorage.getItem("token_Value")

    // axios.post(BaseURL + `courses/`, {
    //   headers: {
    //     'Authorization': `Bearer ${token}`,
    //     'Content-Type': 'application/json',
    //   },
    // }).then((response) => {
      // console.log(response.data);
      // setCourses(response.data.courses);
      // console.log(token);
    // }).catch((error) => {
      // console.log(error);
      // setError(error.message)
    // })

    // useEffect(() => {
    //   addCourses();
    // }, []);


    // console.log("Course Data:", data);

    // try {
    //   const responsedata = await addCourse(data).unwrap();
    //   console.log("response addCourse", responsedata);
    //   message.success("course added successfully");
    //   navigate("/courses-dashboard");
    // } catch (error) {
    //   console.error("Error adding course:", error);
    //   console.log(error);
    //   message.error("error adding course");
    // }
  // };

  return (
    <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
      {/* Sidebar */}
      <div
        className="col-md-2"
        style={{
          backgroundColor: "#F6FAFB",
          padding: "20px",
        }}
      >
        <Navbar />
      </div>

      {/* Main Content */}
      <div className="col-md-10 px-5" style={{ padding: "20px" }}>
        {/* Dashboard Title */}
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ paddingBottom: "10px" }}
        >
          <div className="d-flex align-items-end">
            <h1 className="m-0" style={{ fontSize: "28px", fontWeight: "600" }}>
              Dashboard
            </h1>
            <p className="ml-3 text-muted" style={{ marginLeft: "10px" }}>
              / Add Course
            </p>
          </div>
        </div>

        {/* Content Section */}
        <div
          className="create-content-page mt-4"
          style={{
            backgroundColor: "#fff",
            padding: "40px",
            borderRadius: "8px",
            boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <Title
            level={3}
            style={{ marginBottom: "30px", textAlign: "center" }}
          >
            Add Course
          </Title>

          {/* <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Course Code"
                  validateStatus={errors.code && "error"}
                  help={errors.code?.message} // Display the validation error message
                >
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Course Code is required",
                      minLength: {
                        value: 4,
                        message:
                          "Course code must be at least 4 characters long",
                      },
                    }}
                    render={({ field }) => <Input {...field} />}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Course Name"
                  validateStatus={errors.name && "error"}
                  help={errors.name && "Course Name is required"}
                >
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Course Name is required" }}
                    render={({ field }) => <Input {...field} />}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Category"
                  validateStatus={errors.category && "error"}
                  help={errors.category && "Category is required"}
                >
                  <Controller
                    name="category"
                    control={control}
                    rules={{ required: "Category is required" }}
                    render={({ field }) => (
                      <Select {...field} placeholder="Select Category">
                        <Option value="Programming Languages">
                          Programming Languages
                        </Option>
                        <Option value="Web Development">Web Development</Option>
                        <Option value="Mobile Development">
                          Mobile Development
                        </Option>
                        <Option value="Data Science">Data Science</Option>
                        <Option value="Artificial Intelligence">
                          Artificial Intelligence
                        </Option>
                        <Option value="Software Design">Software Design</Option>
                        <Option value="Design">Design</Option>
                        <Option value="Marketing">Marketing</Option>
                        <Option value="Other">Other</Option>
                      </Select>
                    )}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Description"
                  validateStatus={errors.description && "error"}
                  help={errors.description?.message} // Display the validation error message
                >
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: "Description is required",
                      minLength: {
                        value: 10,
                        message:
                          "Description must be at least 10 characters long",
                      },
                    }}
                    render={({ field }) => <TextArea {...field} rows={4} />}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Course Duration"
                  validateStatus={errors.duration && "error"}
                  help={errors.duration && "Course Duration is required"}
                >
                  <Controller
                    name="duration"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Course Duration is required" }}
                    render={({ field }) => (
                      <InputNumber {...field} style={{ width: "100%" }} />
                    )}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Course Level"
                  validateStatus={errors.level && "error"}
                  help={errors.level && "Course Level is required"}
                >
                  <Controller
                    name="level"
                    control={control}
                    rules={{ required: "Course Level is required" }}
                    render={({ field }) => (
                      <Select {...field} placeholder="Select Level">
                        <Option value="Beginner">Beginner</Option>
                        <Option value="Intermediate">Intermediate</Option>
                        <Option value="Advanced">Advanced</Option>
                      </Select>
                    )}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Course Price"
                  validateStatus={errors.price && "error"}
                  help={errors.price && "Course Price is required"}
                >
                  <Controller
                    name="price"
                    control={control}
                    rules={{ required: "Course Price is required" }}
                    render={({ field }) => (
                      <InputNumber {...field} style={{ width: "100%" }} />
                    )}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Instructor"
                  validateStatus={errors.level && "error"}
                  help={errors.level && "Instructor is required"}
                >
                  <Controller
                    name="instructor"
                    control={control}
                    rules={{ required: "Instructor is required" }}
                    render={({ field }) => (
                      <Select {...field} placeholder="Select Instructor">
                        <Option value="1">Abel</Option>
                        <Option value="2">Omnia</Option>
                        <Option value="3">Mahmoud</Option>
                        <Option value="4">Galal</Option>
                        <Option value="5">Abdelrahman</Option>
                      </Select>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Sections"
                  validateStatus={errors.level && "error"}
                  help={errors.level && "Sections is required"}
                >
                  <Controller
                    name="sections"
                    control={control}
                    rules={{ required: "Sections is required" }}
                    render={({ field }) => (
                      <Select {...field} placeholder="Select Sections">
                        <Option value="1">A</Option>
                        <Option value="2">O</Option>
                        <Option value="3">M</Option>
                        <Option value="4">G</Option>
                        <Option value="5">A</Option>
                      </Select>
                    )}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                    className="me-2"
                  >
                    Save
                  </Button>
                  <Button type="default">Cancel</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form> */}

          <form onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="name" className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control text-gray-700 appearance-none border  rounded-md"
                    id="name"
                    name="name"
                    placeholder="Enter course name"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="code" className="form-label">Code</label>
                  <input
                    type="text"
                    className="form-control text-gray-700 appearance-none border  rounded-md"
                    id="code"
                    name="code"
                    placeholder="Enter course code"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="category" className="form-label">Category</label>
                  <select name="category" id="category" className="form-control appearance-none border  rounded-md" onChange={getData}>
                    <option value="">Select Category</option>
                    <option value="Programming Languages">Programming Languages</option>
                    <option value="Web Development">Web Development</option>
                    <option value="Mobile Development">Mobile Development</option>
                    <option value="Artificial Intelligence">Artificial Intelligence</option>
                    <option value="Software Design">Software Design</option>
                    <option value="Data Science">Data Science</option>
                    <option value="Design">Design</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="duration" className="form-label">Course Duration <span className="fs-6 text-secondary">(inHours)</span></label>
                  <input
                    type="number"
                    className="form-control text-gray-700 appearance-none border  rounded-md"
                    id="duration"
                    name="duration"
                    placeholder="Enter course duration"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="level" className="form-label">Course Level</label>
                  <select name="level" id="level" className="form-control appearance-none border  rounded-md" onChange={getData}>
                    <option value="">Select Level</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="price" className="form-label">Course Price</label>
                  <input
                    type="number"
                    className="form-control text-gray-700 appearance-none border rounded-md"
                    id="price"
                    name="price"
                    placeholder="Enter course price"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="sections" className="form-label">Sections</label>
                  <input
                    type="number"
                    className="form-control text-gray-700 appearance-none border rounded-md"
                    id="sections"
                    name="sections"
                    placeholder="Enter course sections"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="image" className="form-label">Course Image</label>
                  <input
                    type="file"
                    className="form-control text-gray-700 appearance-none border rounded-md"
                    id="image"
                    name="image"
                    accept="image/*"
                    placeholder="Enter course image"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="w-100">
                  <label htmlFor="description" className="form-label">Course Description</label>
                  <textarea
                    className="form-control text-gray-700 appearance-none border rounded-md"
                    id="description"
                    name="description"
                    placeholder="Enter course description"
                    onChange={getData}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="w-100">
                  <button type="submit" className="btn btn-primary me-2">Save</button>
                  <button type="reset" className="btn btn-secondary ms-2">Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;
