import React from "react";
import Style from "./Favorites.module.css";
import { Link } from "react-router-dom";

const categories = [
  {
    title: "Marketing",
    icon: "fa-solid fa-chart-simple",
    color: "#F48E0D",
    backgroundColor: "#FCDDB4",
  },
  {
    title: "Programming Languages",
    icon: "fa-solid fa-code",
    color: "#48BABC",
    backgroundColor: "#C8EBEB",
  },
  {
    title: "Web Development",
    icon: "fa-solid fa-display",
    color: "#5E74EE",
    backgroundColor: "#CED5FA",
  },
  {
    title: "Design",
    icon: "fa-solid fa-paintbrush",
    color: "#48BABC",
    backgroundColor: "#C8EBEB",
  },
  {
    title: "Artificial Intelligence",
    icon: "fa-solid fa-brain",
    color: "#5E74EE",
    backgroundColor: "#CED5FA",
  },
  {
    title: "Data Science",
    icon: "fa-solid fa-server",
    color: "#5E74EE",
    backgroundColor: "#CED5FA",
  },
  {
    title: "Mobile Development",
    icon: "fa-solid fa-mobile-screen-button",
    color: "#48BABC",
    backgroundColor: "#C8EBEB",
  },
  {
    title: "Software Design",
    icon: "fa-regular fa-window-restore",
    color: "#F48E0D",
    backgroundColor: "#FCDDB4",
  },
  {
    title: "Other",
    icon: "fa-solid fa-gears",
    color: "#F48E0D",
    backgroundColor: "#FCDDB4",
  },
  // ... and so on
];

export default function Favorites() {
  return (
    <>
      <div className="container py-5">
        <h4 className="fw-bold">
          Choose <span className="text-color">favourite</span> course from top
          category
        </h4>
        <div className="row mt-5 g-5">
          {categories.map((category) => (
            <div key={category.title} className="col-md-4 text-center">
              <Link to={`/categories/${category.title}`}><div className="flex-column d-flex bg-white shadowbox align-items-center justify-content-center w-100 py-5 px-3 rounded-3">
                <div
                  className="w-25 rounded-3 mb-3"
                  style={{ backgroundColor: category.backgroundColor }}
                >
                  <i
                    className={`fa-solid my-3 ${category.icon}`}
                    style={{ color: category.color, fontSize: '20px' }}
                  ></i>
                </div>
                <h5 className="fw-bold text-black mt-3">{category.title}</h5>
                {/* <p className="small-font gray-text">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam
                  velit necessitatibus dignissimos accusantium, sapiente quis.
                </p> */}
              </div></Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
