import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const data = [
  {
    name: "Jan",
    income: 100,
    expense: 85.66,
  },
  {
    name: "Feb",
    income: 90,
    expense: 75,
  },
  {
    name: "Mar",
    income: 80,
    expense: 65,
  },
  {
    name: "Apr",
    income: 60,
    expense: 40,
  },
  {
    name: "May",
    income: 100,
    expense: 80,
  },
];

const TransactionOverview = () => (
  <div
    style={{
      border: "1px solid #eee",
      backgroundColor: "#f8f9fa",
      borderRadius: "10px",
    }}
  >
    <h3 style={{ textAlign: "center", marginBottom: "10px", fontSize: "18px" }}>
      Transaction Overview
    </h3>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        barCategoryGap="20%"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="income" fill="#4caf50" />
        <Bar dataKey="expense" fill="#f45342" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default TransactionOverview;
