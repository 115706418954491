import React from 'react'

const Reports = () => {
  const list = [
    {
    month: 'Sep 2024',
    study: '80%',
    performance: '90%',
    feedback: '4.5/5',
    total: '86.67'
  },
    {
    month: 'Sep 2024',
    study: '80%',
    performance: '90%',
    feedback: '4.5/5',
    total: '86.67'
  },
    {
    month: 'Sep 2024',
    study: '80%',
    performance: '90%',
    feedback: '4.5/5',
    total: '86.67'
  },
]
  return (
    <div className="row my-4">
      <div className="col-md-12">
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Month</th>
              <th>Study</th>
              <th>Performance</th>
              <th>Feedback</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index}>
                <td>{item.month}</td>
                <td>{item.study}</td>
                <td>{item.performance}</td>
                <td>{item.feedback}</td>
                <td>{item.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Reports