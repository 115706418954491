import React, { useEffect, useState } from "react";
import { Button, Card, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import img from "../../Assets/datascience.jpg";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";
// import { useGetCoursesQuery } from "../../api/apiSlice";

// const courses = [
//   {
//     id: 1,
//     title: "AI For Beginners",
//     description: "Course description...",
//     img: "../../Assets/datascience.jpg",
//   },
//   {
//     id: 2,
//     title: "AI For Beginners",
//     description: "Course description...",
//     img: "../../Assets/datascience.jpg",
//   },
//   {
//     id: 3,
//     title: "AI For Beginners",
//     description: "Course description...",
//     img: "../../Assets/datascience.jpg",
//   },
//   {
//     id: 4,
//     title: "AI For Beginners",
//     description: "Course description...",
//     img: "../../Assets/datascience.jpg",
//   },
//   {
//     id: 5,
//     title: "AI For Beginners",
//     description: "Course description...",
//     img: "../../Assets/datascience.jpg",
//   },
//   {
//     id: 6,
//     title: "AI For Beginners",
//     description: "Course description...",
//     img: "../../Assets/datascience.jpg",
//   },
// ];

const AllCourses = () => {
  // const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  const [courses, setCourses] = useState(null);
    const token = localStorage.getItem("token_Value")
    const getCourses = () => {
        axios.get(BaseURL + `courses/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            console.log(response.data);
            setCourses(response.data.courses);
            console.log(token);
        }).catch((error) => {
            console.log(error);
            // setError(error.message)
        })
    }
    useEffect(() => {
        getCourses();

    }, []);

  // const menuItems = [
  //   { key: "1", label: "Add New Category" },
  //   { key: "2", label: "Edit" },
  //   { key: "3", label: "Delete category" },
  // ];

  return (
  <>{courses!==null?<> <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
  {/* Sidebar */}
  <div
    className="col-md-2"
    style={{
      backgroundColor: "#F6FAFB",
      padding: "20px",
    }}
  >
    <Navbar />
  </div>

  {/* Main Content */}
  <div className="col-md-10 px-5" style={{ padding: "20px" }}>
    {/* Header Section */}
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ paddingBottom: "20px", borderBottom: "1px solid #e0e0e0" }}
    >
      <div className="d-flex align-items-end">
        <h1
          className="m-0"
          style={{ fontSize: "32px", fontWeight: "700", color: "#333" }}
        >
          Dashboard
        </h1>
        <p
          className="ml-3 text-muted"
          style={{ marginLeft: "12px", fontSize: "16px" }}
        >
          / Courses
        </p>
      </div>
    </div>

    {/* Action Buttons */}
    <div
      className="d-flex justify-content-between align-items-center mt-4"
      style={{
        backgroundColor: "#fff",
        padding: "15px 25px",
        borderRadius: "12px",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      }}
    >
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        style={{
          backgroundColor: "#28a745",
          color: "white",
          borderRadius: "8px",
          padding: "8px 20px",
          fontWeight: "600",
        }}
        onClick={() => navigate("/addCourse-dashboard")}
      >
        Add New Course
      </Button>
    </div>

    {/* Courses Grid */}
    <div
      className="courses-container mt-5"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
        gap: "24px",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        boxShadow: "0 1px 6px rgba(0,0,0,0.1)",
      }}
    >
      {courses.map((course) => (
        <Card
          key={course.id}
          hoverable
          cover={
            <img
              alt={course.name}
              src={course?.image}
              style={{
                height: "160px",
                objectFit: "cover",
                borderRadius: "8px 8px 0 0",
              }}
            />
          }
          style={{
            width: "100%",
            borderRadius: "12px",
            boxShadow: "0 4px 10px rgba(0,0,0,0.08)",
          }}
          actions={[
            <EditOutlined key="edit" />,
            <DeleteOutlined key="delete" />,
            <EllipsisOutlined key="ellipsis" />,
          ]}
        >
          <Card.Meta
            title={course.name}
            description={course.description}
            style={{ textAlign: "center" }}
          />
          <div style={{ marginTop: "16px", textAlign: "center" }}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#28a745",
                color: "white",
                borderRadius: "8px",
                fontWeight: "600",
              }}
              onClick={() =>
                navigate(`/courseDetails-dashboard/${course.id}`)
              }
            >
              Course Details
            </Button>
          </div>
        </Card>
      ))}
    </div>
  </div>
</div></>:<></>}</>
  );
};

export default AllCourses;
