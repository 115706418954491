/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

const ProtectUser = ({ children, allowedRoles }) => {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  if (allowedRoles && !allowedRoles.includes(user?.role)) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (!user?.id) {
    return <Navigate to="/" />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return null; // Return null to prevent rendering the protected component
  }

  return children;
};

export default ProtectUser;
