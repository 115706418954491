import React from "react";
import { Link } from "react-router-dom";
import businessImage from "../../Assets/business.jpg";
import programmingImage from "../../Assets/programming.jpg";
import webImage from "../../Assets/webdev.jpg";
import dataImage from "../../Assets/datascience.jpg";
import cyberImage from "../../Assets/cyber.jpg";
import artificialImage from "../../Assets/ai.jpg";
import designImage from "../../Assets/design.jpg";
import langImage from "../../Assets/language.jpg";
import freeImage from "../../Assets/enterpreneurship.jpg";
import { useDarkMode } from "../Contexts/DarkModeContext";
const Categories = () => {
  const { darkMode } = useDarkMode();
  const categories = [
    { name: "Marketing ", image: businessImage, url: "marketing" },
    {
      name: "Programming Languages",
      image: programmingImage,
      url: "programming",
    },
    { name: "Web Development", image: webImage, url: "webdev" },
    { name: "Data Science", image: dataImage, url: "datascience" },
    { name: "Mobile Development", image: cyberImage, url: "mobdev" },
    {
      name: "Artificial Intelligence",
      image: artificialImage,
      url: "artificialI",
    },
    { name: "Software Design", image: designImage, url: "softwaredesign" },
    { name: "Design", image: langImage, url: "design" },
    { name: "Other", image: freeImage, url: "other" },
  ];

  const CategoryCard = ({ category }) => {
    return (
      <div
        className="mt-6 bg-white rounded-4 p-3 my-4 shadowbox d-flex flex-column"
        style={{ height: "340px" }}
      >
        <div style={{ height: "50%", overflow: "hidden" }}>
          <img
            src={category.image}
            className="w-100 rounded-top-4"
            alt=""
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
          />
        </div>

        <div className="border-bottom border-white border-2 pb-4">
          <h5 className="fw-bold ps-3 text-center mt-5">{category.name}</h5>
        </div>

        {/* <p className="font-sm fw-bold ms-2">{category.numCourses} Courses</p> */}

        <div className="mt-auto text-center">
          <Link
            to={`/categories/${category.name}`}
            className="btn bg-color text-white rounded-5"
          >
            View Courses
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className={`${darkMode ? 'bg-dark' : 'bg-white'}`}>
      <div className="container">
      {/*<h1 className="my-4">Choose a Category</h1>*/}
      <div className="row">
        {categories.map((category, index) => (
          <div key={index} className="col-md-4">
            <CategoryCard category={category} />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Categories;
