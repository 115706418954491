import React from "react";
import Style from "./Library.module.css";
import Lessons from "../Lessons/Lessons";
import Favorites from "../Favorites/Favorites";
import Recommended from "../Recommended/Recommended";
import Choices from "../Choices/Choices";
import Personal from "../Personal/Personal";
import Views from "../Views/Views";
import { Helmet } from "react-helmet";
import { useDarkMode } from "../Contexts/DarkModeContext";




export default function Library() {
  const { darkMode } = useDarkMode();
  return (
    <>
    <Helmet>
        <title>Library</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className={`${darkMode ? 'bg-dark' : 'bg-white'} ${darkMode ? 'text-white' : 'text-black'}`}>
      <Lessons />
      <Favorites />
      {/* <Recommended /> */}
      <Choices />
      {/* <Personal /> */}
      <Views />
      </div>
    </>
  );
}
