import React from 'react';
import './Sidebar.css';

export default function Sidebar({ lessons, quizzes, onLessonSelect, onQuizSelect }) {
  return (
    <div className="sidebar">
      <h2 className="sidebar-title">Course Content</h2>
      
      <section className="sidebar-section">
        <h3>Lessons</h3>
        <ul className="sidebar-list">
          {lessons.map((lesson) => (
            <li key={lesson.id} className="sidebar-item">
              <button className="sidebar-button lesson-button" onClick={() => onLessonSelect(lesson)}>
                {lesson.title} - {lesson.duration} Min
              </button>
            </li>
          ))}
        </ul>
      </section>
      
      <section className="sidebar-section">
        <h3>Quizzes</h3>
        <ul className="sidebar-list">
          {quizzes.map((quiz) => (
            <li key={quiz.id} className="sidebar-item">
              <button className="sidebar-button quiz-button" onClick={() => onQuizSelect(quiz.id)}>
                {quiz.title}
              </button>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
