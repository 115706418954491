/* eslint-disable no-unused-vars */
import logo from "./logo.svg";
import "./App.css";
import Layout from "./Components/Layout/Layout";
import Signup from "./Components/Signup/Signup";
import Home from "./Components/Home/Home";
import Pricing from "./Components/Pricing/Pricing";
import Login from "./Components/Login/Login";
import Payment from "./Components/Payment/Payment";
import Library from "./Components/Library/Library";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Profile from "./Components/Profile/Profile";
import Courses from "./Components/Courses/Courses";
import Quiz from "./Components/Quiz/Quiz";
import Categories from "./Components/CoursesCategories/Categories";
import Dashboard from "./Components/Dashboard/Dashboard";
import Page2 from "./Components/Dashboard/Page2";
import InvoiceDashboard from "./Components/InvoiceDashboard/InvoiceDashboard";
import AllCourses from "./Components/CoursesDashboard/AllCourses";
import CourseDetails from "./Components/CoursesDashboard/CourseDetails";
import CreateNewContent from "./Components/CoursesDashboard/CreateNewContent";
import AddCourse from "./Components/CoursesDashboard/AddCourse";
import ProtectUser from "./protector/ProtectUser";
import Unauthorized from "./Components/unauthorized/Unauthorized";
import { DarkModeProvider } from "../src/Components/Contexts/DarkModeContext";
import UserProfile from "./Components/UserProfile/UserProfile";
import '../src/i18n/i18n'
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AllAbrovedCourses from "./Components/AllAbrovedCourses/AllAbrovedCourses";
import AddQuizLesson from "./Components/CoursesDashboard/AddQuizLesson";
import AddQuizCourse from "./Components/CoursesDashboard/AddQuizCourse";
import CoursesByCategory from "./Components/CoursesByCategory/CoursesByCategory";
import CourseView from "./Components/CourseView/CourseView";
import LessonDetails from "./Components/LessonDetails/LessonDetails";
import QuizPage from "./Components/LessonDetails/QuizPage";

let routers = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> }, // Home page will now be the default page at "/"
      { path: "login", element: <Login /> }, // Login page will be accessible at "/login"
      { path: "signup", element: <Signup /> },
      { path: "home", element: <Home /> }, // Home can also be accessed through "/home"
      { path: "pricing", element: <Pricing /> },
      { path: "categories", element: <Categories /> },
      { path: "categories/:category", element: <CoursesByCategory /> },
      { path: "all-courses", element: <AllAbrovedCourses /> },
      {
        path: "payment",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <Payment />
          </ProtectUser>
        ),
      },
      {
        path: "courses/:courseId/lessons",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <LessonDetails />
          </ProtectUser>
        ),
      },
      {
        path: "courses/:courseId",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <CourseView />
          </ProtectUser>
        ),
      },
      {
        path: "/course/:courseId/quiz/:quizId",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <QuizPage />
          </ProtectUser>
        ),
      },
      { path: "unauthorized", element: <Unauthorized /> },
      {
        path: "library",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <Library />
          </ProtectUser>
        ),
      },
      // {
      //   path: "lessonDetails",
      //   element: (
      //     <ProtectUser allowedRoles={["student"]}>
      //       <LessonDetails />
      //     </ProtectUser>
      //   ),
      // },
      {
        path: "profile",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <Profile />
          </ProtectUser>
        ),
      },
      {
        path: "user/*",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <UserProfile />
          </ProtectUser>
        ),
      },
      {
        path: "quiz",
        element: (
          <ProtectUser allowedRoles={["student"]}>
            <Quiz />
          </ProtectUser>
        ),
      },
      {
        path: "admin-dashboard",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <Dashboard />
          </ProtectUser>
        ),
      },
      {
        path: "invoice-dashboard",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <InvoiceDashboard />
          </ProtectUser>
        ),
      },
      {
        path: "courses-dashboard",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <AllCourses />
          </ProtectUser>
        ),
      },
      {
        path: "page2",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <Page2 />
          </ProtectUser>
        ),
      },
      {
        path: "courseDetails-dashboard/:courseId",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <CourseDetails />
          </ProtectUser>
        ),
      },
      {
        path: "createNewCourse-dashboard/:courseId/lesson",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <CreateNewContent />
          </ProtectUser>
        ),
      },
      {
        path: "createNewCourse-dashboard/:courseId/lesson/:lessonId/quiz",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <AddQuizLesson />
          </ProtectUser>
        ),
      },
      {
        path: `/createNewCourse-dashboard/:courseId/quiz`,
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <AddQuizCourse />
          </ProtectUser>
        ),
      },
      {
        path: "addCourse-dashboard",
        element: (
          <ProtectUser allowedRoles={["admin"]}>
            <AddCourse />
          </ProtectUser>
        ),
      },
    ],
  },
]);

export default function App() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Add or remove 'rtl' class to body depending on the current language
    if (i18n.language === 'ar') {
        document.body.classList.add('rtl');
    } else {
        document.body.classList.remove('rtl');
    }

    // Cleanup function to remove the class if component is unmounted
    return () => {
        document.body.classList.remove('rtl');
    };
}, [i18n.language]);
  return (
    <>
      
        <RouterProvider router={routers}></RouterProvider>
      
    </>
  );
}
