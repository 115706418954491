import React from 'react';

export default function LessonContent({ selectedLesson, videoUrl, loadingLesson, loadingVideo }) {
  if (loadingLesson) return <div>Loading lesson...</div>;
  if (!selectedLesson) return <div>Select a lesson to view details.</div>;

  return (
    <div className="lesson-content">
      <h2>{selectedLesson.title}</h2>
      
      {loadingVideo ? (
          <div>Loading video...</div>
        ) : (
            videoUrl && <video src={videoUrl} controls width="100%" />
        )}
        <p>{selectedLesson.description}</p>
    </div>
  );
}
