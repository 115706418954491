import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { FaArrowDown } from "react-icons/fa";

const withdrawnData = [
  { day: "Sunday", amount: 75 },
  { day: "Monday", amount: 40 },
  { day: "Tuesday", amount: 60 },
];

const WithdrawnCard = () => (
  <div
    style={{
      border: "1px solid #eee",
      backgroundColor: "#f8f9fa",
      borderRadius: "10px",
      padding: "20px",
      margin: "10px",
    }}
  >
    <h2>
      <FaArrowDown style={{ color: "red", marginRight: "5px" }} />
      $45,673
    </h2>
    <p>Withdrawn</p>
    <ResponsiveContainer width="100%" height={150}>
      <BarChart data={withdrawnData}>
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="amount" fill="orange" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default WithdrawnCard;
