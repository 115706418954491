import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";

const QuickTransfer = () => {
  const [amount, setAmount] = useState(875);

  const containerStyle = {
    backgroundColor: "#f8f9fa",
    padding: "25px",
    borderRadius: "15px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    margin: "0 auto",
  };

  const sectionStyle = {
    marginBottom: "40px",
  };

  const userInfoStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  const userNameStyle = {
    margin: 0,
    fontSize: "18px",
    fontWeight: "bold",
    color: "#333",
  };

  const userHandleStyle = {
    color: "#888",
  };

  const recentFriendsStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    position: "relative",
  };

  const friendIconStyle = (index) => ({
    position: "absolute",
    left: `${index * 30}px`,
    zIndex: index,
  });

  const inputStyle = {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    fontSize: "16px",
  };

  const rangeInputStyle = {
    width: "100%",
    marginTop: "10px",
  };

  const balanceStyle = {
    fontSize: "14px",
    color: "#888",
    marginTop: "5px",
  };

  const buttonStyle = {
    backgroundColor: "#28a745",
    color: "white",
    padding: "12px",
    width: "100%",
    border: "none",
    borderRadius: "5px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  return (
    <div style={containerStyle}>
      <h3 style={{ textAlign: "center", marginBottom: "25px" }}>
        Quick Transfer
      </h3>

      {/* User Info */}
      <div style={{ ...sectionStyle, ...userInfoStyle }}>
        <FaUserCircle size={50} color="#6c757d" />
        <div>
          <h4 style={userNameStyle}>Abel</h4>
          <span style={userHandleStyle}>@abelbr</span>
        </div>
      </div>

      {/* Recent Friends */}
      <div style={sectionStyle}>
        <h4>Recent Friends</h4>
        <div style={recentFriendsStyle}>
          {[1, 2, 3, 4, 5].map((_, idx) => (
            <FaUserCircle
              key={idx}
              size={40}
              color="#6c757d"
              style={friendIconStyle(idx)}
            />
          ))}
        </div>
      </div>

      {/* Transfer Inputs */}
      <div style={sectionStyle}>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          style={inputStyle}
        />
        <input
          type="range"
          min="0"
          max="5000"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          style={rangeInputStyle}
        />
        <div style={balanceStyle}>Your Balance: $456,345.62</div>
      </div>

      {/* Submit Button */}
      <button
        style={buttonStyle}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#218838")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#28a745")}
      >
        Post Now
      </button>
    </div>
  );
};

export default QuickTransfer;
