import React from 'react'
import { useDarkMode } from '../../Contexts/DarkModeContext';
import Performance from '../../CircularPerformance/CircularPerformance';

const Assignment = () => {
  const { darkMode } = useDarkMode();
  const Lists = [
    {
      title: "Introduction about XD",
      status: "In progress",
      date: "10/05/2024",
    },
    {
      title: "Introduction about XD",
      status: "Completed",
      date: "10/05/2024",
    },
    {
      title: "Introduction about XD",
      status: "Late",
      date: "10/05/2024",
    },
    {
      title: "Introduction about XD",
      status: "Completed",
      date: "10/05/2024",
    },
  ]
  return (
    <><div className="row">
      <div className='col-md-9'>
        <div className='border border-success p-3 my-5 rounded'>
          <h2 className='text-color'>Assignments Lists</h2>
          <table className='table'>
            {Lists.map((item, index) => (
              <tr key={index} className={`border-bottom border-success row py-3 text-center ${darkMode ? 'text-white' : 'text-black'}`}>
                <td className='col'>{item.title}</td>
                <td className={`col text-center ${item.status === 'In progress' ? 'bg-warning' : item.status === 'Completed' ? 'bg-success' : 'bg-danger'}`}>{item.status}</td>
                <td className='col'>{item.date}</td>
              </tr>
            ))}
          </table>
        </div>
        <div className='border border-success p-3 my-5 rounded'>
          <h2 className='text-color'>Class Performance</h2>
          <table class="table table-borderless table-striped">
            <thead className="text-center">
              <tr>
                <th scope="col">Rank</th>
                <th scope="col">Name</th>
                <th scope="col">Rate of Previous Assignments</th>
                <th scope="col">Points</th>
              </tr>
            </thead>
            <tbody className="text-center ">
              <tr className="fw-bold border-bottom border-success">
                <th
                  scope="row"
                  className="d-flex align-items-center justify-content-center"
                >
                  <p>1</p>
                  <i class="fa-solid fa-sort-up pt-1 ms-2 text-color"></i>
                </th>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="fw-bold font-sm">Charlie Rawal</p>
                  </div>
                </td>
                <td>88%</td>
                <td className="text-color fw-bold">13.450</td>
              </tr>
              <tr className="fw-bold border-bottom border-success">
                <th scope="row" className="d-flex justify-content-center">
                  2
                  <i
                    class="fa-solid fa-sort-down ms-1"
                    style={{ color: "#A9834F" }}
                  ></i>
                </th>
                <td>
                  <div className="d-flex align-items-center justify-content-center">

                    <p className="fw-bold font-sm">Ariana Agrawl</p>
                  </div>
                </td>
                <td>53%</td>
                <td className="text-color">10.333</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-3">
      <div className='border border-success p-3 my-5 rounded'>
        <Performance />
      </div>
      </div>
    </div></>
  )
}

export default Assignment