import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api/apiSlice";
import Cookies from "js-cookie";

let dataFromCookie = Cookies.get("user");

let userObject = {};
if (dataFromCookie) {
  try {
    userObject = JSON.parse(dataFromCookie);
  } catch (e) {
    console.error("Failed to parse user cookie", e);
    userObject = {};
  }
}

const initialState = {
  user: userObject,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      Cookies.remove("user");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.user;
        Cookies.set("user", JSON.stringify(payload.user), { expires: 7 });
      },
    );
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
