import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const data = [
  { name: "Investment", value: 76 },
  { name: "Installment", value: 30 },
  { name: "Restaurant", value: 5 },
  { name: "Property", value: 96 },
];

const COLORS = ["#FF8042", "#00C49F", "#FFBB28", "#0088FE"];

const Spendings = () => {
  const containerStyle = {
    backgroundColor: "#f8f9fa",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
  };

  const spendingItemStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  };

  return (
    <div style={containerStyle}>
      <h3>Spendings</h3>
      {data.map((item, index) => (
        <div key={item.name} style={spendingItemStyle}>
          <div>
            <span>{item.name}</span>
            <h4>${item.value * 10}/$2000</h4>
          </div>
          <PieChart width={100} height={100}>
            <Pie
              data={[{ value: item.value }, { value: 100 - item.value }]}
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={40}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
              <Cell fill="#f0f0f0" />
            </Pie>
          </PieChart>
        </div>
      ))}
    </div>
  );
};

export default Spendings;
