import React, { useEffect, useState } from 'react';
import Style from './Dashboard.module.css';
import { PieChart, Pie, Cell } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DashboardNavBar from '../DashboardNavbar/DashboardNavbar';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { FaSquare } from "react-icons/fa6";
import { FaUsers, FaChalkboardTeacher, FaCheckCircle, FaClipboardList } from 'react-icons/fa';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BaseURL } from '../../api/BaseURL';

const COLORS = ['#37E89E', '#039B6F', '#FFC000', '#FF5733', '#FFAA33', '#33CFFF'];

const Dashboard = () => {
    const [analytics, setAnalytics] = useState(null);
    const token = localStorage.getItem("token_Value");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BaseURL}courses/analytics`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setAnalytics(response.data.analytics);
            } catch (error) {
                console.error("Error fetching analytics data", error);
            }
        };
        fetchData();
    }, [token]);

    if (!analytics) {
        return <p>Loading...</p>;
    }

    // Data preparation for each section
    const coursesData = analytics.coursesPerCategoryCount.map((category, index) => ({
        name: category.category,
        value: category.count,
        color: COLORS[index % COLORS.length],
    }));

    const enrollmentData = [
        { name: 'Enrolled', value: analytics.enrollments },
        { name: 'Not Enrolled', value: 100 - analytics.enrollments } // Assuming total is 100 for visualization purposes
    ];

    const hoursSpentData = [
        { name: 'Spent', value: analytics.hoursSpent },
        { name: 'Remaining', value: 100 - analytics.hoursSpent } // Assuming total is 100 for visualization purposes
    ];

    const coursesPerInstructorData = analytics.coursesPerInstructorCount.map(instructor => ({
        name: `Instructor ${instructor.instructor}`, // Customize as needed
        value: instructor.count,
    }));

    const coursesStatusCountData = analytics.coursesStatusCount.map(status => ({
        name: status.status || 'Unknown',
        value: status.count,
    }));

    return (
        <div className="row">
            <div className="col-md-1">
                <DashboardNavBar />
            </div>
            <div className={`${Style.Dashboard} col-md-11 px-5 d-flex flex-column`}>
                <div className="d-flex pb-5 pt-3 justify-content-between">
                    <div className="d-flex align-items-end">
                        <h1 className="m-0">Dashboard</h1>
                        <p><span className="gray-text">Dashboard /</span> Promotion</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <Link to="/admin-dashboard">
                            <div className="rounded-pill main-border mx-2 d-flex align-items-center justify-content-center" style={{ width: "40px", height: "40px" }}>
                                <p>1</p>
                            </div>
                        </Link>
                        <Link to="/page2">
                            <div className="rounded-pill main-border d-flex align-items-center justify-content-center text-white bg-color" style={{ width: "40px", height: "40px" }}>
                                <p>2</p>
                            </div>
                        </Link>
                        <Link to="/invoice-dashboard">
                            <div className="rounded-pill main-border d-flex align-items-center justify-content-center text-white bg-color" style={{ width: "40px", height: "40px" }}>
                                <p>3</p>
                            </div>
                        </Link>
                        <div className="d-flex align-items-center blue-border text-color rounded-3 py-2 px-3 mx-3 bg-white">
                            <p>May 04, 24 - May 28, 24 EGY</p>
                            <MdOutlineKeyboardArrowDown />
                        </div>
                    </div>
                </div>

                {/* General Statistics */}
                <div className="row mb-4">
                    <div className="col-md-3">
                        <div className="stat-card blue-border bg-white p-4 rounded-4 text-center">
                            <FaClipboardList className="stat-icon mb-2 fs-5" />
                            <h3>Courses</h3>
                            <p className='fs-5 fw-bold'>{analytics.courses}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="stat-card blue-border bg-white p-4 rounded-4 text-center">
                            <FaUsers className="stat-icon mb-2 fs-5" />
                            <h3>Students</h3>
                            <p className='fs-5 fw-bold'>{analytics.students}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="stat-card blue-border bg-white p-4 rounded-4 text-center">
                            <FaChalkboardTeacher className="stat-icon mb-2 fs-5" />
                            <h3>Instructors</h3>
                            <p className='fs-5 fw-bold'>{analytics.instructors}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="stat-card blue-border bg-white p-4 rounded-4 text-center">
                            <FaCheckCircle className="stat-icon mb-2 fs-5" />
                            <h4>Completed Courses</h4>
                            <p className='fs-5 fw-bold'>{analytics.completedCourses}</p>
                        </div>
                    </div>
                </div>

                {/* Charts Section */}
                <div className="row">
                    {/* Sale by Category Pie Chart with Legend */}
                    <div className="col-md-4">
                        <div className="chart-card blue-border bg-white p-4 rounded-4">
                            <h4>Courses by Category</h4>
                            <PieChart width={200} height={200}>
                                <Pie
                                    data={coursesData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label
                                >
                                    {coursesData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                            {/* Legend for Categories */}
                            <div className="mt-3">
                                {coursesData.map((entry, index) => (
                                    <div key={`legend-${index}`} className="d-flex align-items-center">
                                        <FaSquare style={{ color: entry.color }} />
                                        <span className="ms-2">{entry.name} ({entry.value})</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Monthly Revenue Bar Chart */}
                    <div className="col-md-8">
                        <div className="chart-card blue-border bg-white p-4 rounded-4">
                            <h4>Monthly Revenue</h4>
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={[]}> {/* Empty data due to the lack of last12monthRevenuePerMonth */}
                                    <CartesianGrid />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="revenue" fill="#37E89E" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>

                {/* Enrollment and Hours Display */}
                <div className="row mt-4">
                    {/* Enrollments Display */}
                    <div className="col-md-4">
                        <div className="stat-card blue-border bg-white p-4 rounded-4">
                            <h4>Enrollments</h4>
                            <p className='fs-4'>{analytics.enrollments}</p>
                        </div>
                    </div>

                    {/* Hours Spent Display */}
                    <div className="col-md-4">
                        <div className="stat-card blue-border bg-white p-4 rounded-4">
                            <h4>Hours Spent</h4>
                            <p className='fs-4'>{analytics.hoursSpent}</p>
                        </div>
                    </div>

                    {/* Reviews Display */}
                    <div className="col-md-4">
                        <div className="stat-card blue-border bg-white p-4 rounded-4">
                            <h4>Reviews</h4>
                            <p className='fs-4'>{analytics.reviews || 0}</p> {/* Assuming there's a 'reviews' field in analytics */}
                        </div>
                    </div>
                </div>

                {/* Courses per Instructor Pie Chart with Legend */}
                <div className="row my-4">
                    <div className="col-md-4">
                        <div className="chart-card blue-border bg-white p-4 rounded-4">
                            <h4>Courses per Instructor</h4>
                            <PieChart width={200} height={200}>
                                <Pie
                                    data={coursesPerInstructorData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label
                                >
                                    {coursesPerInstructorData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                            {/* Legend for Courses per Instructor */}
                            <div className="mt-3">
                                {coursesPerInstructorData.map((entry, index) => (
                                    <div key={`legend-instructor-${index}`} className="d-flex align-items-center">
                                        <FaSquare style={{ color: COLORS[index % COLORS.length] }} />
                                        <span className="ms-2">{entry.name} ({entry.value})</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Courses Status Count Pie Chart with Legend */}
                    <div className="col-md-4">
                        <div className="chart-card blue-border bg-white p-4 rounded-4">
                            <h4>Courses Status Counts</h4>
                            <PieChart width={200} height={200}>
                                <Pie
                                    data={coursesStatusCountData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label
                                >
                                    {coursesStatusCountData.map((entry, index) => (
                                        <Cell key={`cell-status-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                            {/* Legend for Courses Status Counts */}
                            <div className="mt-3">
                                {coursesStatusCountData.map((entry, index) => (
                                    <div key={`legend-status-${index}`} className="d-flex align-items-center">
                                        <FaSquare style={{ color: COLORS[index % COLORS.length] }} />
                                        <span className="ms-2">{entry.name} ({entry.value})</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
