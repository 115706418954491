import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Unauthorized = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const getSubtitle = () => {
    if (!user?.id) {
      return "Please sign in to access this page.";
    } else {
      return "Sorry, you are not authorized to access this page.";
    }
  };

  return (
    <Result
      status="403"
      title="403"
      subTitle={getSubtitle()}
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Go back
        </Button>
      }
    />
  );
};

export default Unauthorized;
