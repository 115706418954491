import React, { useEffect, useState } from "react";
import Style from "./FifthSection.module.css";
import laptop from "../../Assets/img2.webp";
import man1 from "../../Assets/man1 (1).jpg";
import man2 from "../../Assets/man2.jpg";
import people from "../../Assets/img1.jpg";
import code from "../../Assets/img3.jpg";
import { useMediaQuery } from "react-responsive";
import { useGetCoursesQuery } from "../../api/apiSlice";
import { BaseURL } from "../../api/BaseURL";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Stars from "../Stars/Stars";

export default function FifthSection() {
  const { t } = useTranslation();
  const isScreenSmall = useMediaQuery({ maxWidth: 576 });
  // const { data: courses, error, isLoading } = useGetCoursesQuery();
  const [courses, setCourses] = useState(null);
  const token = localStorage.getItem("token_Value")
  const getCourses = () => {
    axios.get(BaseURL + `courses/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      console.log(response.data);
      setCourses(response.data.courses);
      console.log(token);
    }).catch((error) => {
      console.log(error);
      // setError(error.message)
    })
  }
  useEffect(() => {
    getCourses();

  }, []);
  const courseData = [
    {
      imgSrc: laptop,
      lessons: "25x Lesson",
      title: "Supervised Machine Learning: Regression and Classification",
      instructor: "Wade Warren",
      role: "Python Developer",
      stars: 4,
      category: "Design",
    },
    {
      imgSrc: people,
      lessons: "16x Lesson",
      title: "Programming for everyone: Getting started with Python",
      instructor: "Brooklyn Simmons",
      role: "Programmer",
      stars: 4,
      category: "Design",
    },
    {
      imgSrc: code,
      lessons: "16x Lesson",
      title: "Programming for everyone: Getting started with Python",
      instructor: "Brooklyn Simmons",
      role: "Programmer",
      stars: 4,
      category: "Design",
    },
    {
      imgSrc: code,
      lessons: "16x Lesson",
      title: "Programming for everyone: Getting started with Python",
      instructor: "Brooklyn Simmons",
      role: "Programmer",
      stars: 4,
      category: "Design",
    },
  ];
  return (
    <>
      {courses !== null ? <><div className="container">
        <div className="row justify-content-between mb-2 mx-3">
          <div
            className={`col-md-3 ${isScreenSmall ? "text-center mb-3" : ""}`}
          >
            <h4 className="fw-bold">
              <span className="text-color">{t('ourCourses')}</span>
            </h4>
          </div>
          <div className="col-md-2 d-flex justify-content-end">
            <Link to={'/categories'} className="btn bg-color text-white rounded-5 px-4">
              {t('seeAll')}
            </Link>
          </div>
        </div>

        <div
          className={`row mb-5 mt-2 mx-2 ${isScreenSmall ? "justify-content-center" : "flex-nowrap"}`}
        >
          {courses && courses.slice(0, 4).map((course) => (
            <div className="col-md-3" key={course.title}>
              <Link to={`/courses/${course.id}`}><div className="bg-white rounded-4 p-3  shadowbox">
              <div className="d-flex align-items-center font-sm gray-text mb-2">
                <i class="fa-solid fa-signal me-1"></i>
                <p>{course.level}</p>
              </div>
              <div style={{ height: '200px' }}>
                <img src={course.image} className="w-100 h-100 rounded-3" alt={course.title} />
              </div>
              <div className="d-flex justify-content-between mt-3 mb-1 gray-text">
                <div className="d-flex align-items-center font-sm">
                  <i className="fa-solid fa-table-cells-large me-1"></i>
                  <p>{course.category}</p>
                </div>
                <div className="d-flex align-items-center font-sm">
                  <i className="fa-regular fa-clock me-1"></i>
                  <p>{course.duration} Hr</p>
                </div>
              </div>
              <Stars rating={course.rate} />
              <h5 className="fw-bold">{course.title}</h5>
              <p className="font-sm gray-text mt-4">{course.description}</p>
              <div className="d-flex mt-3">
                <div className="d-flex w-75 align-items-center">
                  {/* <img src={course.instructorImage} className="w-25 rounded-pill" alt={course.instructor} /> */}
                  <p className="font-sm fw-bold ms-2">{course.user.firstName}</p>
                </div>
                <div className="d-flex align-items-center justify-content-end w-100">
                  {/* <del className="gray-text font-sm me-2"><em>{course.price}</em></del> */}
                  <h6 className="fw-bold text-color m-0">${course.price}</h6>
                </div>
              </div>
            </div></Link>
            </div>
          ))}
        </div>
      </div></> : <></>}
    </>
  );
}
