import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseURL } from '../../api/BaseURL';
import './QuizPage.css';

function QuizPage() {
  const { quizId, courseId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState(null);
  const token = localStorage.getItem("token_Value");

  useEffect(() => {
    const fetchQuizQuestions = async () => {
      try {
        const response = await axios.get(`${BaseURL}courses/${courseId}/quiz/${quizId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setQuestions(response.data.quiz.questions);
      } catch (error) {
        console.error("Error fetching quiz questions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchQuizQuestions();
  }, [quizId, courseId, token]);

  const handleAnswerSelect = (questionId, answerId) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: answerId,
    }));
  };

  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleBack = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleSubmit = async () => {
    const answers = Object.keys(selectedAnswers).map((questionId) => ({
      questionId: parseInt(questionId),
      answerId: selectedAnswers[questionId],
    }));
  
    // Wrap answers in an object
    const body = { answers };
  
    console.log("Answers to submit:", body); // Log to check the structure
  
    try {
      const response = await axios.post(`${BaseURL}courses/${courseId}/quiz/${quizId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setResult(response.data);
      setShowResult(true);
    } catch (error) {
      console.error("Error submitting quiz:", error);
    }
  };
  
  if (loading) return <div className="loading">Loading questions...</div>;
  
  if (showResult) return (
    <div className="result-container">
      <div className="result-circle">
        <span>{result.score}/{questions.length}</span>
      </div>
        <p className='fw-bold fs-4'>Your Score</p>
    </div>
  );

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="quiz-page">
      <div className="question-card">
        <h2 className="question-title">Question {currentQuestionIndex + 1}</h2>
        <p className="question-text">{currentQuestion.text}</p>
        <div className="answer-options">
          {currentQuestion.answers.map((answer) => (
            <button
              key={answer.id}
              className={`answer-button ${selectedAnswers[currentQuestion.id] === answer.id ? 'selected' : ''}`}
              onClick={() => handleAnswerSelect(currentQuestion.id, answer.id)}
            >
              {answer.text}
            </button>
          ))}
        </div>
        <div className="navigation-buttons">
          {currentQuestionIndex > 0 && <button onClick={handleBack} className="nav-button">Back</button>}
          {currentQuestionIndex < questions.length - 1 ? (
            <button onClick={handleNext} className="nav-button">Next</button>
          ) : (
            <button onClick={handleSubmit} className="nav-button submit-button">Submit</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuizPage;
