import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { BaseURL } from "./BaseURL";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BaseURL}`,
    prepareHeaders: (headers) => {
      const token = Cookies.get("user")
      // const token = localStorage.getItem('token_Value')
        ? JSON.parse(Cookies.get("user"))
        : null;

      if (token) {
        headers.set("authorization", `Bearer ${token.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (userData) => ({
        url: "/auth/login",
        method: "POST",
        body: userData,
      }),
      transformResponse: (response) => {
        // Make sure you are storing the token
        Cookies.set("user", JSON.stringify(response));  // The response should contain the token
        localStorage.setItem('token_Value',response.token)
        console.log("Stored token in cookies:", response.token); // Log to check
        return response;
      },
    }),
    

    registerUser: builder.mutation({
      query: (userData) => {
        return {
          url: "/users/sign-up",
          method: "POST",
          body: userData,
        };
      },
    }),

    getCourses: builder.query({
      query: () => {
        const token = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
        if (token) {
          console.log("Bearer token:", token.token);  // Log the token to check its value
        } else {
          console.error("No token found in cookies.");
        }
        let authHeader = token ? token.token : "";
        return {
          url: "/courses/",
          method: "GET",
          headers: { Authorization: `Bearer ${authHeader}` },
        };
      },
      transformResponse: (response) => {
        console.log("API response getCourses:", response);
        return response.courses;
      },
      onError: (error) => {
        if (error.status === 401) {
          console.error("Unauthorized. Token might be invalid or expired.");
          // Optionally, handle token refresh or redirection to login
        }
      }
    }),
    
    
    

    addCourse: builder.mutation({
      query: (data) => ({
        url: "/courses/",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${localStorage.getItem('token_Value')}` },
      }),
      transformResponse: (response) => {
        console.log("API response addCourse:", response);
        return response;
      },
    }),

    getSpecificCourse: builder.query({
      query: (courseId) => {
        const token = Cookies.get("user")
          ? JSON.parse(Cookies.get("user"))
          : null;
        let authHeader = token.token;
        return {
          url: `/courses/${courseId}`,
          method: "GET",
          headers: { Authorization: `Bearer ${authHeader}` },
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterUserMutation,
  useGetCoursesQuery,
  useAddCourseMutation,
  useGetSpecificCourseQuery,
} = api;
