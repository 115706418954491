import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Typography } from "antd";
// import { useParams } from "react-router-dom";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSpecificCourseQuery } from "../../api/apiSlice";
import img from "../../Assets/datascience.jpg";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";

const { Title, Paragraph } = Typography;

// Sample courses data
// const courses = [
//   {
//     id: 1,
//     title: "AI For Beginners",
//     description: "Introduction to AI concepts and tools.",
//     duration: "06 weeks",
//     assignments: 10,
//     level: "Beginner",
//     totalClasses: 20,
//     timing: "Flexible",
//     img: "../../Assets/datascience.jpg",
//   },
// ];

const CourseDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // // const [courseId, setCourseId] = useState("");
  // const [currentCourseDetails, setCurrentCourseDetails] = useState({});

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   let courseId = searchParams.get("courseId");
  //   // setCourseId(courseId);
  // }, [location]);

  // const { data: courseDetails, error: currentCourseError } =
  //   useGetSpecificCourseQuery(courseId);

  // useEffect(() => {
  //   if (courseDetails) {
  //     setCurrentCourseDetails(courseDetails.course);
  //   }
  // }, [courseDetails, currentCourseError]);

  // console.log("course:", currentCourseDetails);

  const { courseId } = useParams();
  const token = localStorage.getItem("token_Value");
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState(null);
  const getCourse = ()=>{
    console.log("Course ID:", courseId); // Debug log for the courseId
    axios
      .get(`${BaseURL}courses/${courseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((response) => {
      console.log(response.data);
      setCourse(response.data.course);
      
      console.log(token);
    }).catch((error) => {
      console.log(error);
      // setError(error.message)
    })
  }
  useEffect(() => {
    if (courseId) {
      getCourse(); // Fetch course details only if courseId is present
    }
  }, [courseId]);
  const getLessons = ()=>{
    console.log("Course courseId:", courseId); // Debug log for the courseId
    axios
      .get(`${BaseURL}courses/${courseId}/lessons`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((response) => {
      console.log(response.data);
      setLessons(response.data.lessons);
      
      console.log(token);
    }).catch((error) => {
      console.log(error);
      // setError(error.message)
    })
  }
  useEffect(() => {
    if (courseId) {
      getLessons(); // Fetch course details only if courseId is present
    }
  }, [courseId]);

  return (
    <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
      {/* Sidebar */}
      <div
        className="col-md-2"
        style={{
          backgroundColor: "#F6FAFB",
          padding: "20px",
        }}
      >
        <Navbar />
      </div>

      {/* Main Content */}
      <div className="col-md-10 px-5" style={{ padding: "20px" }}>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ paddingBottom: "10px" }}
        >
          {/* Dashboard Title */}
          <div className="d-flex align-items-end">
            <h1 className="m-0" style={{ fontSize: "28px", fontWeight: "600" }}>
              Dashboard
            </h1>
            <p className="ml-3 text-muted" style={{ marginLeft: "10px" }}>
              / Course Detail
            </p>
          </div>
        </div>

        {/* Course Details Section */}
        <div
          className="d-flex justify-content-between align-items-center mt-4"
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
            marginTop: "20px",
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <img
                src={course?.image}
                alt="Course"
                style={{
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
                }}
              />
            </Col>
            <Col span={12}>
              <Title level={3}>{course?.name}</Title>
              <Paragraph>Category: {course?.category}</Paragraph>
              <Paragraph>
                Description: {course?.description}
              </Paragraph>
              <Paragraph>Level: {course?.level}</Paragraph>
              <Paragraph>Duration: {course?.duration} Hours</Paragraph>
              <Paragraph>Rating: {Array.from({ length: course?.rate }).map(
                      (_, starIndex) => (
                        <i
                          key={starIndex}
                          className="fa-solid fa-star"
                          style={{ color: "#FFD43B" }}
                        ></i>
                      ),
                    )}</Paragraph>
            </Col>
          </Row>

          <div style={{ marginTop: "40px" }}>
            <Title level={4}>Course Content</Title>
            <Button
              type="primary"
              style={{
                backgroundColor: "green",
                color: "white",
                marginBottom: "20px",
              }}
              onClick={() => navigate(`/createNewCourse-dashboard/${course?.id}/lesson`)}
            >
              Add New Content
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "green",
                color: "white",
                marginBottom: "20px",
                marginLeft: "20px",
              }}
              onClick={() => navigate(`/createNewCourse-dashboard/${course?.id}/quiz`)}
            >
              Add Course Quiz
            </Button>

            {/* Course Content List */}
            <div
              className="course-content-list"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "20px",
              }}
            >
              <Card
                hoverable
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: "10px",
                  borderRadius: "8px",
                  boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
                }}
              >
                <Paragraph style={{ marginBottom: "0" }}>
                  Lesson 1: Introduction
                </Paragraph>
              </Card>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Title level={4}>Course Content</Title>
          <table className="table text-center">
            <thead>
              <tr>
                <th className="col">Lesson Title</th>
                <th className="col">Lesson Description</th>
                <th className="col">Lesson Duration</th>
                <th className="col"></th>
              </tr>
            </thead>
            <tbody>
                {lessons?.map((lesson,index)=>(
                  <tr key={index}>
                    <td>{lesson.title}</td>
                    <td>{lesson.description}</td>
                    <td>{lesson.duration} Min</td>
                    <td><Link to={`/createNewCourse-dashboard/${course.id}/lesson/${lesson.id}/quiz`}><button className="btn bg-color text-white">Add Quiz</button></Link></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
