import React, { useEffect, useState } from 'react';
import Style from './Views.module.css';
import Img1 from '../../Assets/view1.jpg';
import person1 from '../../Assets/img12.jpg';
import { useDarkMode } from '../Contexts/DarkModeContext';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { BaseURL } from '../../api/BaseURL';
import Stars from '../Stars/Stars';

// const courses = [
//   {
//     title: "AWS Certified Solution Architect",
//     category: "Design",
//     duration: "3 Month",
//     imageUrl: Img1,
//     instructor: "Sara",
//     instructorImage: person1,
//     originalPrice: 100,
//     discountedPrice: 80,
//     description:
//       "Learn the skills and knowledge to architect and deploy secure, scalable, and cost-effective solutions on AWS.",
//   },
//   // Add more course objects here following the same structure
// ];

export default function Views() {
  const { darkMode } = useDarkMode();
  const { category } = useParams();
  const isScreenSmall = useMediaQuery({ maxWidth: 576 });
  const [courses, setCourses] = useState(null);
  const token = localStorage.getItem("token_Value")
  const getCourses = () => {
    axios.get(BaseURL + `courses/mostenrolled/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      console.log(response.data);
      setCourses(response.data.courses);
      console.log(token);
    }).catch((error) => {
      console.log(error);
      // setError(error.message)
    })
  }
  useEffect(() => {
    getCourses();

  }, []);
  return (
    <>
      {courses && (<div className="bg-greenlight text-black">
        <div className="container py-5">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="fw-bold">Students are viewing</h5>
            <Link to={'/categories'} className="btn fw-bold text-color font-sm">
              See All
            </Link>
          </div>
          <div className="row pb-5 pt-3">
            {courses && courses.map((course) => {
              return [...Array(4)].map(() => (
                <div key={course.title + Math.random()} className="col-md-3">
                  <div className="bg-white rounded-4 p-3  shadowbox">
                    <div style={{ height: '200px' }}>
                      <img src={course.image} className="w-100 h-100 rounded-3" alt={course.title} />
                    </div>
                    <div className="d-flex justify-content-between mt-3 mb-1 gray-text">
                      <div className="d-flex align-items-center font-sm">
                        <i className="fa-solid fa-table-cells-large me-1"></i>
                        <p>{course.category}</p>
                      </div>
                      <div className="d-flex align-items-center font-sm">
                        <i className="fa-regular fa-clock me-1"></i>
                        <p>{course.duration} Hr</p>
                      </div>
                    </div>
                    <Stars rating={course.rate} />
                    <h5 className="fw-bold">{course.title}</h5>
                    <p className="font-sm gray-text mt-4">{course.description}</p>
                    <div className="d-flex mt-3">
                      <div className="d-flex w-75 align-items-center">
                        {/* <img src={course.instructorImage} className="w-25 rounded-pill" alt={course.instructor} /> */}
                        <p className="font-sm fw-bold ms-2">{course.user.firstName}</p>
                      </div>
                      <div className="d-flex align-items-center">
                        {/* <del className="gray-text font-sm me-2"><em>{course.price}</em></del> */}
                        <h6 className="fw-bold text-color m-0">${course.price}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ));
            })}
          </div>
        </div>
      </div>)}
    </>
  );
}
