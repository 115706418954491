import React from "react";
import Style from "./SecondSection.module.css";
import { useMediaQuery } from "react-responsive";
import { useDarkMode } from "../Contexts/DarkModeContext";
import { useTranslation } from "react-i18next";

export default function SecondSection() {
  const { t } = useTranslation();
  const { darkMode } = useDarkMode();
  const isScreenSmall = useMediaQuery({ maxWidth: 576 });
  const data = [
    { value: '15K+', label: t('students') },
    { value: '75%', label: t('totalSuccess') },
    { value: '35', label: t('mainQuestions') },
    { value: '26', label: t('chiefExperts') },
    { value: '12', label: t('yearsOfExperience') },
  ];

  const featureData = [
    {
      icon: 'fa-regular fa-newspaper',
      title: t('onlineBilling'),
      description: t('onlineBillingDescription'),
    },
    {
      icon: 'fa-solid fa-calendar-days',
      title: t('easyScheduling'),
      description: t('easySchedulingDescription'),
    },
    {
      icon: 'fa-solid fa-users',
      title: t('customerTracking'),
      description: t('customerTrackingDescription'),
    },
  ];

  return (
    <>

    <div className="container">
        <div className="justify-content-center pt-3 d-flex flex-column flex-md-row">
          <div className="col-md-5 px-5 text-center">
            <h3>
              {t('ourSuccess')} <span className="text-color"></span>
            </h3>
            <p className="small-font px-3 pt-2">
              {t('successDescription')}
            </p>
          </div>
        </div>

        <div className="justify-content-center py-5 d-flex flex-column flex-md-row">
          {data.map((item, index) => (
            <div key={index} className="col-md-2 d-flex flex-column align-items-center">
              <h1 className={`${Style.gradcolor} ${isScreenSmall ? 'display-1' : 'display-3'} m-0`}>
                {item.value}
              </h1>
              <div className="d-flex justify-content-center">
                <p className={`${isScreenSmall ? 'fs-2 mb-4' : ''}`}>{item.label}</p>
              </div>
            </div>
          ))}
        </div>

        <div className={`row justify-content-center ${isScreenSmall ? "" : "pb-5"}`}>
          <div className="col-md-5 text-center">
            <h3 className="fw-bold">
              {t('allInOne')}
            </h3>
            <p className="font-sm gray-text">
              {t('cloudSoftwareDescription')}
            </p>
          </div>
        </div>

        <div className=" m-5 justify-content-center d-flex flex-column flex-md-row">
          {featureData.map((item, index) => (
            <div
              key={index}
              className={`${Style.shadowbox} col-md-3 p-4 d-flex ${darkMode ? 'bg-light':'bg-transparent'} flex-column align-items-center rounded-3 text-center ${isScreenSmall ? 'my-5' : 'mx-5'
                }`}
            >
              <div className="rounded-5 bg-black d-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px' }}>
                <i className={`text-white ${item.icon} fs-4`}></i>
              </div>
              <div className="mt-5 px-3 text-color">
                <h5 className="fw-bold">{item.title}</h5>
                <p className="font-sm gray-text mt-4">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
