import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Sidebar from './Sidebar';
import LessonContent from './LesssonContent';
import QuizPage from './QuizPage';
import './LessonDetails.css';
import axios from 'axios';
import { BaseURL } from '../../api/BaseURL';

function LessonDetails() {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [quizzes, setQuizzes] = useState([]);
  const [loadingLesson, setLoadingLesson] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const navigate = useNavigate();
  const { courseId } = useParams();
  const token = localStorage.getItem("token_Value");

  // Fetch all lessons for the course
  const fetchLessons = async () => {
    try {
      const response = await axios.get(`${BaseURL}courses/${courseId}/lessons`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setLessons(response.data.lessons);
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }
  };

  // Fetch all quizzes for the course
  const fetchQuizzes = async () => {
    try {
      const response = await axios.get(`${BaseURL}courses/${courseId}/quizzes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setQuizzes(response.data.quizzes);
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    }
  };

  // Fetch selected lesson details and its video URL
  const fetchLessonDetails = async (lessonId) => {
    setLoadingLesson(true);
    try {
      const response = await axios.get(`${BaseURL}courses/${courseId}/lesson/${lessonId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const lesson = response.data.lesson;
      setSelectedLesson(lesson);
      fetchVideo(lesson.signedUrl);
    } catch (error) {
      console.error("Error fetching lesson details:", error);
    } finally {
      setLoadingLesson(false);
    }
  };

  // Fetch the video using the signed URL from lesson details
  const fetchVideo = async (videoApiUrl) => {
    setLoadingVideo(true);
    try {
      const response = await axios.get(videoApiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Referrer: "http://localhost:3000",
          Range: "bytes=0-",
        },
        responseType: "blob",
      });
      const videoBlob = new Blob([response.data], { type: 'video/mp4' });
      const videoObjectUrl = URL.createObjectURL(videoBlob);
      setVideoUrl(videoObjectUrl);
    } catch (error) {
      console.error("Error fetching video:", error);
    } finally {
      setLoadingVideo(false);
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchLessons();
      fetchQuizzes();
    }
  }, [courseId]);

  const handleLessonSelect = (lesson) => {
    fetchLessonDetails(lesson.id);
  };

  const handleQuizSelect = (quizId) => {
    navigate(`/course/${courseId}/quiz/${quizId}`);
  };

  return (
    <div className="lesson-details">
      <Sidebar
        lessons={lessons}
        quizzes={quizzes}
        onLessonSelect={handleLessonSelect}
        onQuizSelect={handleQuizSelect}
      />
      <Routes>
        <Route
          path="/"
          element={
            <LessonContent
              selectedLesson={selectedLesson}
              videoUrl={videoUrl}
              loadingLesson={loadingLesson}
              loadingVideo={loadingVideo}
            />
          }
        />
        <Route path="/quiz/:quizId" element={<QuizPage />} />
      </Routes>
    </div>
  );
}

export default LessonDetails;
