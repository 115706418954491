import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const data = [
  {
    name: "Investment",
    value: 1415,
    max: 2000,
    fill: "#a16ee2",
  },
  {
    name: "Restaurant",
    value: 1567,
    max: 5000,
    fill: "#4caf81",
  },
  {
    name: "Installment",
    value: 487,
    max: 10000,
    fill: "#6c5ce7",
  },
  {
    name: "Property",
    value: 3890,
    max: 4000,
    fill: "#fd6a32",
  },
];

const BarSpendings = () => (
  <div
    style={{
      border: "1px solid #eee",
      backgroundColor: "#f8f9fa",
      borderRadius: "10px",
      paddingBottom: "10px",
    }}
  >
    <ResponsiveContainer
      width="100%"
      height={300}
      style={{ marginBottom: "10px" }}
    >
      <h3
        style={{ textAlign: "center", marginBottom: "10px", fontSize: "18px" }}
      >
        Bar Spendings
      </h3>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 20, right: 30, left: 50, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis
          dataKey="name"
          type="category"
          width={90}
          tick={{ fontSize: 14, fontWeight: 600 }}
        />
        <Tooltip />
        <Bar dataKey="value" maxBarSize={20}>
          <LabelList
            dataKey="value"
            position="right"
            style={{ fontSize: 10 }}
          />
          {data.map((entry, index) => (
            <Bar
              key={`bar-${index}`}
              dataKey="value"
              fill={entry.fill}
              barSize={10}
              background={{ fill: "#eee" }}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default BarSpendings;
