import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Overview from '../Overview/Overview';
import Assignment from '../Assignment/Assignment';
import Reports from '../Reports/Reports';
import FileStorage from '../FileStorage/FileStorage';
import Inbox from '../Inbox/Inbox';
import Settings from '../Settings/Settings';
import logo from '../../../Assets/logo.png';

const UserSideBar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();


    const sections = [
        {
            title: 'Overview',
            path: 'overview',
            fullPath: '/user/overview',
            icon: `fa-solid fa-shapes`,
            component: Overview
        },
        {
            title: 'Assignment',
            path: 'assignment',
            fullPath: '/user/assignment',
            icon: `fa-solid fa-clipboard`,
            component: Assignment
        },
        {
            title: 'Reports',
            path: 'reports',
            fullPath: '/user/reports',
            icon: `fa-solid fa-chart-pie`,
            component: Reports
        },
        {
            title: 'File Storage',
            path: 'file-storage',
            fullPath: '/user/file-storage',
            icon: `fa-solid fa-file-circle-plus`,
            component: FileStorage
        },
        {
            title: 'Inbox',
            path: 'inbox',
            fullPath: '/user/inbox',
            icon: `fa-solid fa-inbox`,
            component: Inbox
        },
        {
            title: 'Settings',
            path: 'settings',
            fullPath: '/user/settings',
            icon: `fa-solid fa-gear`,
            component: Settings
        },
    ]


    useEffect(() => {
        if (sections.findIndex(section => section.fullPath === pathname) === -1) {
            navigate('/user/overview')
        }
    }, [pathname, navigate])
    return (
        <>
            <div className="d-flex flex-column flex-shrink-0 p-3 bg-light rounded-start">
            {/* <span className="fs-4">Sidebar</span> */}
            <div className='w-25'><img src={logo} alt="" /></div>
            {/* <hr/> */}
            <ul className="nav nav-pills flex-column mb-auto">
                        {
                            sections.map((section, index) => (
                                <li key={index} className={`my-1 border-bottom ${pathname === section.fullPath ? 'nav-item' : null}`}>
                                    {
                                        pathname === section.fullPath ?
                                        <Link to={section.fullPath} className="nav-link link-light active" aria-current="page">
                                            <i className={section.icon}></i> {section.title}
                                        </Link>
                                        :
                                        <Link to={section.fullPath} className="nav-link link-dark">
                                            <i className={section.icon}></i> {section.title}
                                        </Link>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>
            <div>
            </div></>
    )
}

export default UserSideBar