import React, { useEffect, useState } from "react";
import Style from "./Home.module.css";
import FirstSection from "../FirstSection/FirstSection";
import SecondSection from "../SecondSection/SecondSection";
import ThirdSection from "../ThirdSection/ThirdSection";
import FourthSection from "../FourthSection/FourthSection";
import FifthSection from "../FifthSection/FifthSection";
import { Helmet } from "react-helmet";
import { useDarkMode } from "../Contexts/DarkModeContext";
export default function Home() {
  // Initialize darkMode state from localStorage (default to 'false' if not found)
  const { darkMode } = useDarkMode();

  return (
    <>
      <Helmet>
        <title>Home</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div className={darkMode ? "bg-dark text-light" : "bg-white text-dark"}>
      <FirstSection />
      <FifthSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      </div>
    </>
  );
}
