import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { FaRegFileAlt } from "react-icons/fa";
import RevenueCard from "./RevenueCard";
import WithdrawnCard from "./WithdrawnCard";
import Spendings from "./Spendings";
import QuickTransfer from "./QuickTransfer";
import Transactions from "./Transactions";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { Link } from "react-router-dom";
import BarSpendings from "./BarSpendings";
import TransactionOverview from "./TransactionOverview";

const data = [
  { value: 20 },
  { value: 30 },
  { value: 50 },
  { value: 40 },
  { value: 60 },
  { value: 45 },
  { value: 70 },
];

const InvoiceCard = ({ title, count, color }) => (
  <div
    style={{
      border: "1px solid #eee",
      backgroundColor: "#f8f9fa",
      borderRadius: "10px",
      padding: "20px",
      width: "100%",
      margin: "10px",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h2>{count}</h2>
      <FaRegFileAlt style={{ color: color, fontSize: "24px" }} />
    </div>
    <p>{title}</p>
    <ResponsiveContainer width="100%" height={50}>
      <AreaChart data={data}>
        <Area
          type="monotone"
          dataKey="value"
          stroke={color}
          fill={color}
          fillOpacity={0.3}
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

const InvoiceDashboard = () => (
  <>
    <div className="row">
      <div className="col-md-2" style={{ backgroundColor: " #F6FAFB" }}>
        <Navbar />
      </div>
      <div className="col-md-10 px-5" style={{ backgroundColor: " #F6FAFB" }}>
        <div className="d-flex pb-5 pt-3 justify-content-between">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ paddingBottom: "10px" }}
          >
            <div className="d-flex align-items-end">
              <h1
                className="m-0"
                style={{ fontSize: "28px", fontWeight: "600" }}
              >
                Dashboard
              </h1>
              <p className="ml-3 text-muted" style={{ marginLeft: "10px" }}>
                / Invoice
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Link to="/admin-dashboard">
              <div
                className="rounded-pill border mx-2 d-flex align-items-center justify-content-center"
                style={{ width: "40px", height: "40px" }}
              >
                <p>1</p>
              </div>
            </Link>

            <Link to="/page2">
              <div
                className="rounded-pill border d-flex align-items-center justify-content-center bg-primary text-white"
                style={{ width: "40px", height: "40px" }}
              >
                <p>2</p>
              </div>
            </Link>

            <Link to="/invoice-dashboard">
              <div
                className="rounded-pill border d-flex align-items-center justify-content-center bg-primary text-white"
                style={{ width: "40px", height: "40px" }}
              >
                <p>3</p>
              </div>
            </Link>
          </div>
        </div>

        <div
          className="d-flex"
          style={{
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "20px",
              flex: "2",
            }}
          >
            <InvoiceCard title="Total Invoices" count="2,478" color="gray" />
            <InvoiceCard title="Paid Invoices" count="983" color="green" />
            <InvoiceCard title="Unpaid Invoices" count="1,256" color="red" />
            <InvoiceCard
              title="Total Invoices Sent"
              count="652"
              color="orange"
            />
          </div>

          <div style={{ flex: "1", marginLeft: "20px" }}>
            <RevenueCard />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-4">
            <Spendings />
          </div>
          <div className="col-md-4">
            <QuickTransfer />
          </div>
          <div className="col-md-4">
            <WithdrawnCard />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <div className="row">
              <div className="col-12 mb-4">
                <InvoiceCard
                  title="Weekly wallet usage"
                  count="43%"
                  color="blue"
                />
              </div>
              <div className="col-12">
                <Transactions />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 mb-4">
                <BarSpendings />
              </div>
              <div className="col-md-12">
                <TransactionOverview />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default InvoiceDashboard;
