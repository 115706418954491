import React, { useEffect } from "react";
import Style from "./Layout.module.css";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import './Layout.module.css'
import { DarkModeProvider } from "../Contexts/DarkModeContext";

export default function Layout() {

  const [darkModeEdu, setDarkModeEdu] = useLocalStorage('darkModeEdu', false);
  useEffect(()=>{
    if(darkModeEdu){
      document.body.classList.add('dark');
    }else{
      document.body.classList.remove('dark');
    }
  },[darkModeEdu])
  // useEffect(() => {
  //   if (darkMode) {
  //     document.body.classList.add('dark-mode');
  //     document.body.classList.remove('light-mode');
  //   } else {
  //     document.body.classList.add('light-mode');
  //     document.body.classList.remove('dark-mode');
  //   }
  // }, [darkMode]);
  return (
    <>
      <DarkModeProvider>
      <Navbar />
      <Outlet>
      
      </Outlet>
      <Footer />
      </DarkModeProvider>
    </>
  );
}