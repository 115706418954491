import React, { useEffect, useState } from "react";
import Style from "./Lessons.module.css";
import library1 from "../../Assets/libraryImg1.jpg";
import libraryImg3 from "../../Assets/IibraryImg3.png";
import libraryImg4 from "../../Assets/libraryImg4 (2).jpeg";
import img12 from "../../Assets/img12.jpg";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const lessons = [
  {
    imgSrc: libraryImg3,
    title: "AWS Certified Solution Architect",
    progress: 75,
    lessonNumber: 5,
    totalLessons: 7,
    profileImgSrc: img12,
    profileName: "Lina",
  },
  {
    imgSrc: libraryImg4,
    title: "AWS Certified Solution Architect",
    progress: 75,
    lessonNumber: 5,
    totalLessons: 7,
    profileImgSrc: img12,
    profileName: "Lina",
  },
  {
    imgSrc: library1,
    title: "AWS Certified Solution Architect",
    progress: 75,
    lessonNumber: 5,
    totalLessons: 7,
    profileImgSrc: img12,
    profileName: "Lina",
  },
];

export default function Lessons() {
  const { user } = useSelector((state) => state.auth);
  const [courses, setCourses] = useState(null);
  const token = localStorage.getItem("token_Value");

  const getCourses = () => {
    axios.get(BaseURL + `courses/uncompleted/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      const transformedCourses = response.data.courses.map((course) => ({
        ...course,
        coverdLessons: course.coverdLessons.split(',')
      }));
      setCourses(transformedCourses);
      console.log(transformedCourses);
    }).catch((error) => {
      console.log(error);
      // setError(error.message)
    });
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      <div className="">
        {courses && courses.length > 0 ? (
          <div className={`bg-greenlight text-black`}>
            <div className="container py-4">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="fw-bold">Welcome {user.firstName}, ready for your next lesson</h3>
                <Link to={'/all-courses'} className="btn fw-bold text-color font-sm">View History</Link>
              </div>
              <div className="row">
                {courses.slice(0, 3).map((course) => (
                  <div key={course.id} className="col-md-4">
                    <Link to={`/courses/${course.id}/lessons`}>
                      <div className="bg-white shadow rounded-4 p-3 my-4">
                        <div>
                          <p className="small-font gray-text mt-1 fw-bold">{course.category}</p>
                          <img src={course.image} className="w-100 rounded-3" alt="" />
                        </div>
                        <p className="fw-bold my-2">{course.name}</p>
                        <div className="d-flex w-50 align-items-center">
                          <p className="fw-bold my-3 font-sm">{course.user.firstName + ' ' + course.user.lastName}</p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="small-font gray-text mt-1 fw-bold">
                            Uncompleted Lessons: {course.coverdLessons.length}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center my-5">
            <p className="fw-bold">
              You are not enrolled in any courses yet.
            </p>
          </div>
        )}
      </div>
    </>
  );
}
