import React from 'react'
import Style from './FourthSection.module.css'
import rightimg from '../../Assets/img5.jpg'
import leftimg from '../../Assets/img11.png'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'


export default function FourthSection() {
    const { t } = useTranslation();
    const isScreenSmall=useMediaQuery({maxWidth:576})
    return <>

<div className="container py-0">
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className={`col-md-6 mt-4 ${isScreenSmall ? '' : 'ms-5 ps-3'}`}>
                        <h3 className="fw-bold"><span className="text-color">{t('screenReaderFeature')}</span></h3>
                        <p className="gray-text">{t('screenReaderDescription')}</p>
                    </div>
                    <div className={`col-md-3 ${isScreenSmall ? 'd-flex justify-content-end' : ''}`}>
                        <img src={rightimg} className={`${Style.rightimg} ${isScreenSmall ? 'w-50' : 'w-75'}`} alt="" />
                    </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className={`col-md-5 p-0 ${isScreenSmall ? '' : 'offset-1'}`}>
                        <img src={leftimg} className={`${isScreenSmall ? 'w-75' : 'w-100'}`} alt="" />
                    </div>
                    <div className="col-md-5 px-2 d-flex flex-column justify-content-center my-4">
                        <h3 className="fw-bold"><span className="text-color">{t('chatWithInstructors')}</span></h3>
                        <p className="gray-text">{t('chatDescription')}</p>
                    </div>
                </div>
            </div>
    
    </>
}


